import { Box, useStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface SelectSizeProps {
  selectedSizes: Array<number>;
  setSelectedSizes: Function;
  error?: String;
  children: ReactNode;
}

const SelectSize: React.FC<SelectSizeProps> = ({
  selectedSizes,
  setSelectedSizes,
  error,
  children,
}) => {
  const headerStyles = useStyleConfig("h3");

  const handleSelectSize = (size) => {
    setSelectedSizes((_selectedSizes) => {
      if (_selectedSizes.includes(size)) {
        return _selectedSizes.filter((value) => value !== size);
      }

      if (selectedSizes.length === 2) {
        return _selectedSizes;
      }

      return [..._selectedSizes, size];
    });
  };

  return (
    <>
      <Box mx="auto" textAlign="center">
        <Box __css={headerStyles} mt="1rem">
          WHAT SIZE DO YOU WEAR?
        </Box>
        <Box mt="1rem" maxWidth="20rem" mx="auto">
          Select 2 of your usual sized so we can show you available styles.
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          maxWidth="21rem"
          mx="auto"
          mt="1rem"
          justifyContent="center"
        >
          {[2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24].map((number) => (
            <Box
              as="button"
              border="1px solid gray"
              m={["0.8rem", null, null, "1rem"]}
              borderRadius="100%"
              w="3rem"
              h="3rem"
              alignItems="center"
              display="flex"
              justifyContent="center"
              onClick={() => handleSelectSize(number)}
              bgColor={selectedSizes.includes(number) ? "black" : "transparent"}
              color={
                selectedSizes.includes(number)
                  ? "white"
                  : selectedSizes?.length === 2
                  ? "gray"
                  : "black"
              }
              cursor="pointer"
              position="relative"
            >
              {!selectedSizes.includes(number) &&
                selectedSizes?.length === 2 && (
                  <Box
                    position="absolute"
                    w="105%"
                    h="105%"
                    backgroundColor="white"
                    opacity={0.6}
                    cursor="not-allowed"
                  />
                )}
              {number}
            </Box>
          ))}
        </Box>
        <Box textAlign="left" mt="2rem" color="red">
          {error}
        </Box>
      </Box>
      {children}
    </>
  );
};

export default SelectSize;
