import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";

import { productsPerPage } from "@app/constants";
import { FilterQuerySet } from "@utils/collections";
import {
  DeliveryFilterType,
  LocationFilterType,
  PriceFilterType,
} from "@utils/filterData";
import {
  filtersChangeHandler,
  getFilters,
  getLocationFilter,
  getPricesFilter,
} from "@utils/filters";

import { useLocalStorage } from "./useLocalStorage";

interface PresetAttributeFilterType {
  [key: string]: string[];
}
interface ProductFilterInputType {
  presetAttributeFilter?: PresetAttributeFilterType;
  presetPriceFilters?: PriceFilterType;
  presetLocationFilter?: LocationFilterType[];
}

const useProductFiltering = ({
  presetAttributeFilter,
  presetPriceFilters,
  presetLocationFilter,
}: ProductFilterInputType = {}) => {
  const router = useRouter();

  const [sort, setSort] = useQueryParam("sortBy", StringParam);

  const [queryParams, setQueryParams] = useQueryParam(
    "filters",
    FilterQuerySet
  );

  const [keyword, setKeyword] = useQueryParam("keyword", StringParam);

  const [clientFiltered, setClientFiltered] = useState(false);

  const [pricesFilter, setPricesFilter] = useState<PriceFilterType[]>(
    presetPriceFilters?.gte || presetPriceFilters?.lte
      ? [presetPriceFilters]
      : getPricesFilter(queryParams)
  );

  const [locationFilter, setLocationFilter] = useState<LocationFilterType[]>(
    presetLocationFilter?.length
      ? presetLocationFilter
      : getLocationFilter(queryParams)
  );

  const [deliveryFilter, setDeliveryFilter] =
    useLocalStorage<DeliveryFilterType>("delivery-filter", {
      postCode: "",
      date: "",
    });

  useEffect(() => {
    if (!presetLocationFilter?.length) {
      setLocationFilter(getLocationFilter(queryParams));
    }
    if (!presetPriceFilters) {
      setPricesFilter(getPricesFilter(queryParams));
    }
    if (queryParams?.prices?.length === 0) {
      delete queryParams.prices;
      setQueryParams(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (clientFiltered) {
      setClientFiltered(false);
    }
  }, [clientFiltered]);

  useEffect(() => {
    setClientFiltered(true);
  }, [deliveryFilter]);

  const filters = getFilters(
    productsPerPage,
    pricesFilter,
    locationFilter,
    queryParams,
    sort,
    deliveryFilter || null,
    keyword
  );

  if (presetAttributeFilter) {
    filters.attributes = { ...filters.attributes, ...presetAttributeFilter };
  }

  const handleClearFilters = () => setQueryParams({});

  // Handle attribute filters changing
  const handleFiltersChange = (name, value, singleValue) => {
    setClientFiltered(true);
    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )(name, value, singleValue);
  };

  const handlePricesFilter = (pricesFilterValue: PriceFilterType[]) => {
    setClientFiltered(true);
    const priceSlugs = pricesFilterValue.map((filter) => filter.slug || "");

    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )("prices", priceSlugs.join("_"), true);

    setPricesFilter(pricesFilterValue);
  };

  const handleLocationFilter = (locationFilterValue: LocationFilterType) => {
    setClientFiltered(true);
    filtersChangeHandler(
      { attributes: queryParams },
      queryParams,
      setQueryParams
    )("location", String(locationFilterValue.slug));

    const newLocationFilter: LocationFilterType[] = [...locationFilter];

    // Remove the item if it's already being filtered
    const existingIndex = newLocationFilter.indexOf(locationFilterValue);
    if (existingIndex > -1) {
      newLocationFilter.splice(existingIndex);
    } else {
      newLocationFilter.push(locationFilterValue);
    }

    setLocationFilter(newLocationFilter);
  };

  const hasAttributeFilter = Object.keys(filters?.attributes || {}).length > 0;
  const hasDeliveryFilter = !deliveryFilter?.date && !deliveryFilter?.postCode;
  const hasPriceFilter = pricesFilter;
  // Not considering location filter as we want SEO ratings to appear on location landing pages
  // const hasLocationFilter =  locationFilter.some(lf => lf.gte || lf.lte);
  const isFiltered = hasAttributeFilter || hasDeliveryFilter || hasPriceFilter;

  const handleOrderChange = (value: { value?: string; label: string }) => {
    const searchParams = new URLSearchParams(window.location.search);
    if (value.value) {
      if (value.value === "price" || value.value === "-price") {
        if (!router.asPath.includes("filters")) {
          searchParams.set("filters", "rental-period_4-days");
        } else if (!router.asPath.includes("rental-period")) {
          searchParams.set(
            "filters",
            `${searchParams.get("filters")}.rental-period_4-days`
          );
        }

        if (!router.asPath.includes("sortBy")) {
          searchParams.append("sortBy", value.value);
        } else {
          searchParams.set("sortBy", value.value);
        }
      }
    } else {
      searchParams.delete("sortBy");
    }
    setSort(value?.value || "");
  };

  return {
    clientFiltered,
    filters,
    pricesFilter,
    locationFilter,
    deliveryFilter,
    hasDeliveryFilter,
    handleClearFilters,
    handleFiltersChange,
    handlePricesFilter,
    handleLocationFilter,
    setDeliveryFilter,
    isFiltered,
    handleOrderChange,
    keyword,
    setKeyword,
  };
};

export default useProductFiltering;
