import { Box } from "@chakra-ui/react";
import React from "react";

import { Button } from "@components/atoms";

const SubmitButton: React.FC = ({ handleNextStep, label }) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="end"
    flex={1}
    mx="auto"
  >
    <Button
      size="sm"
      variant="secondary"
      w="10rem"
      mt="0.5rem"
      onClick={handleNextStep}
    >
      {label}
    </Button>
  </Box>
);

export default SubmitButton;
