import { useAuth } from "@the-volte/svc-core-sdk";
import { useRouter } from "next/router";

import useLogin from "@hooks/useLogin";
import { paths } from "@paths";

export default () => {
  const router = useRouter();
  const { user } = useAuth();
  const { register } = useLogin();

  const redirectToDashboard = () => {
    if (!user) {
      register(undefined, paths.accountAddressBook);
      return;
    }
    if (!user?.defaultShippingAddress) {
      router.push(paths.accountAddressBook);
      return;
    }
    // This is an external link to www-dashboard SPA (so router push won't work)
    document.location.href = paths.accountLending;
  };

  return {
    redirectToDashboard,
  };
};
