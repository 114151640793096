export interface AttributeList {
  [attributeSlug: string]: string[];
}

export type PriceFilterType = {
  gte?: number | null;
  lte?: number | null;
  slug?: string;
};

export type LocationFilterType = {
  gte: number;
  lte: number;
  slug: string;
  name: string;
};

export type DeliveryFilterType = {
  postCode: string;
  date: string;
};

export const priceFilterOptions = [
  {
    name: "Under $100",
    slug: "under-100",
  },
  {
    name: "$100 - $150",
    slug: "100-150",
  },
  {
    name: "$150 - $200",
    slug: "150-200",
  },
  {
    name: "$200 - $250",
    slug: "200-250",
  },
  {
    name: "Over $250",
    slug: "over-250",
  },
] as const;

export const priceFilterValues: { [key: string]: PriceFilterType } = {
  "under-100": {
    gte: 0,
    lte: 99.99,
    slug: "under-100",
  },
  "100-150": {
    gte: 100,
    lte: 150,
    slug: "100-150",
  },
  "150-200": {
    gte: 150,
    lte: 200,
    slug: "150-200",
  },
  "200-250": {
    gte: 200,
    lte: 250,
    slug: "200-250",
  },
  "over-250": {
    gte: 250.99,
    lte: null,
    slug: "over-250",
  },
};

export const locationFilterValues: { [key: string]: LocationFilterType } = {
  adelaide: {
    gte: 5000,
    lte: 5799,
    slug: "adelaide",
    name: "Adelaide",
  },
  brisbane: {
    gte: 4000,
    lte: 4999,
    slug: "brisbane",
    name: "Brisbane",
  },
  canberra: {
    gte: 2600,
    lte: 2618,
    slug: "canberra",
    name: "Canberra",
  },
  darwin: {
    gte: 800,
    lte: 899,
    slug: "darwin",
    name: "Darwin",
  },
  hobart: {
    gte: 7000,
    lte: 7789,
    slug: "hobart",
    name: "Hobart",
  },
  melbourne: {
    gte: 3000,
    lte: 3999,
    slug: "melbourne",
    name: "Melbourne",
  },
  perth: {
    gte: 6000,
    lte: 6797,
    slug: "perth",
    name: "Perth",
  },
  sydney: {
    gte: 2000,
    lte: 2599,
    slug: "sydney",
    name: "Sydney",
  },
};

export const locationFilterOptions: LocationFilterType[] = Object.keys(
  locationFilterValues
).map((key) => ({
  name: locationFilterValues[key].name,
  slug: locationFilterValues[key].slug,
  lte: locationFilterValues[key].lte,
  gte: locationFilterValues[key].gte,
}));
