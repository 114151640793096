import { NextRouter } from "next/router";

import { Attribute } from "@graphql/gqlTypes/Attribute";
import { IFilters } from "@types";

import {
  AttributeList,
  DeliveryFilterType,
  LocationFilterType,
  locationFilterValues,
  PriceFilterType,
  priceFilterValues,
} from "./filterData";
import { UknownObject } from "./tsUtils";

/*
 * Functions used for initial state
 */
const getBrowserParams = () =>
  new URLSearchParams(window.location.search).toString();

export const getBrowserPath = (router: NextRouter) =>
  [router.asPath.split("?")[0], getBrowserParams()].join("?");

const getAttributesFromQueryParams = (
  queryParams: UknownObject
): IFilters["attributes"] => {
  // Add filters to be excluded from filters.attributes before ...attributes
  const { prices, location, lenderRating, ...attributes } = queryParams;
  return attributes;
};

export const getFilters = (
  pageSize: number,
  pricesFilter: PriceFilterType[] | [],
  locationFilter: LocationFilterType[],
  queryParams: UknownObject,
  sort: string | null | undefined,
  deliveryFilter: DeliveryFilterType | null,
  keyword: string | null | undefined
): IFilters => ({
  attributes: getAttributesFromQueryParams(queryParams),
  pageSize,
  lenderRating: queryParams?.lenderRating,
  prices: pricesFilter,
  postcodes: locationFilter.map((lf) => ({ gte: lf.gte, lte: lf.lte })),
  sortBy: sort || null,
  availableDeliveryDate: deliveryFilter?.date || undefined,
  availableDeliveryPostcode: deliveryFilter?.postCode || undefined,
  search: keyword || undefined,
});

export const getPricesFilter = (queryParams: UknownObject): any => {
  const prices = (queryParams?.prices || [])
    .filter((slug) => slug in priceFilterValues)
    .map((slug) => priceFilterValues[slug]);
  return prices || [];
};

export const getLocationFilter = (
  queryParams: UknownObject
): LocationFilterType[] =>
  (queryParams?.location || []).map((location) => ({
    gte: locationFilterValues[location]?.gte,
    lte: locationFilterValues[location]?.lte,
    slug: location,
    name: locationFilterValues[location]?.name,
  }));

/*
 * Change handlers
 */
export const filtersChangeHandler =
  (
    filters: { attributes: IFilters["attributes"] },
    attributeFilters: UknownObject<string[]>,
    setAttributeFilters: (newValue: UknownObject<string[]>) => void
  ) =>
  (name: string, value: string, singleValue?: boolean) => {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes[name].length === 1) {
          const att = { ...attributeFilters };
          delete att[name];
          setAttributeFilters({
            ...att,
          });
        } else {
          setAttributeFilters({
            ...attributeFilters,
            [name]: attributeFilters[name].filter((item) => item !== value),
          });
        }
      } else if (singleValue && filters.attributes[name].length >= 1) {
        setAttributeFilters({
          ...attributeFilters,
          [name]: [value],
        });
      } else {
        setAttributeFilters({
          ...attributeFilters,
          [name]: [...attributeFilters[name], value],
        });
      }
    } else {
      setAttributeFilters({ ...attributeFilters, [name]: [value] });
    }
  };

export const getActiveFilterAttributes = (
  filterAttributes: AttributeList | undefined,
  attributes: Attribute[]
) => {
  const getAttribute = (attributeSlug: string, valueSlug: string) => {
    const valueName = attributes
      ?.find(({ slug }) => attributeSlug === slug)
      ?.values?.find(({ slug }) => valueSlug === slug)?.name;
    return valueName
      ? {
          attributeSlug,
          valueName,
          valueSlug,
        }
      : undefined;
  };

  return (
    filterAttributes &&
    Object.keys(filterAttributes)
      .reduce(
        (acc, key) =>
          acc.concat(
            filterAttributes[key].map((valueSlug) =>
              getAttribute(key, valueSlug)
            )
          ),
        []
      )
      .filter(Boolean)
  );
};
