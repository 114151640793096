import { Box, useStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { DatePicker } from "@components/organisms";

interface SelectDeliveryDateProps {
  date: Date | undefined;
  setDate: Function;
  children: ReactNode;
}

const SelectDeliveryDate: React.FC<SelectDeliveryDateProps> = ({
  date,
  setDate,
  children,
}) => {
  const headerStyles = useStyleConfig("h3");
  const tempRange = { from: date };

  return (
    <>
      <Box mx="auto" textAlign="center">
        <Box __css={headerStyles} mt="1rem" fontSize="1.4rem">
          WHEN&apos;S YOUR DELIVERY DATE?
        </Box>
        <Box mt="1rem" maxWidth="20rem" mx="auto">
          Pick a delivery date 1-2 days before your event
        </Box>
        <Box mx="auto">
          <DatePicker
            disabledDays={[]}
            range={tempRange}
            handleOnDayClicked={setDate}
          />
        </Box>
      </Box>
      {children}
    </>
  );
};

export default SelectDeliveryDate;
