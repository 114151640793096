import { Box, useStyleConfig } from "@chakra-ui/react";
import React from "react";

interface SelectShippingProps {
  postCode: String;
  handlePostChange: Function;
  error?: String;
  children: React.ReactNode;
}

const SelectShipping: React.FC<SelectShippingProps> = ({
  postCode,
  handlePostChange,
  error,
  children,
}) => {
  const headerStyles = useStyleConfig("h3");
  return (
    <>
      <Box mx="auto" mt="1rem">
        <Box __css={headerStyles} mt="1rem">
          WHERE ARE WE SHIPPING?
        </Box>
        <Box
          mt={["3rem", null, null, "1rem"]}
          display="flex"
          justifyContent="center"
        >
          <Box
            w={["13rem", null, null, "20rem"]}
            as="input"
            placeholder="Your post code"
            type="number"
            p="0.7rem 1rem"
            border="1px solid gray"
            value={postCode}
            onChange={(e) => handlePostChange(e.target.value)}
          />
        </Box>
        <Box textAlign="left" mt="2rem" color="red">
          {error}
        </Box>
      </Box>
      {children}
    </>
  );
};

export default SelectShipping;
